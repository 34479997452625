.about {
  min-height: 100%;
  background-image: url(./Blue_GrungeBG.jpg);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.about__main-container {
  padding: 10% 10%;
  font-size: 20px;
}

.about__sub-container {
  display: grid;
  grid-template-rows: auto;
  padding: 5% 5%;
  color: white;
  background: purple;
  border: 5px palevioletred solid;
  border-radius: 20px;
}

.about__sub-container h1 {
  margin-top: 0;
  font-family: "Rock Salt", cursive;
  text-align: center;
}

.about__sub-container a {
  color: skyblue;
  font-weight: bold;
}

.about__sub-container a:hover,
.about__sub-container a:active {
  color: yellow;
  font-weight: bold;
}

.about__headshot {
  float: left;
  margin: 15px;
  margin-right: 25px;
  margin-left: 0;
  width: 50%;
  border: 5px skyblue solid;
  border-radius: 25px;
}

.about__youtube-container {
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.about__youtube-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@media (max-width: 768px) {
  .about__main-container {
    padding: 25% 0%;
    font-size: 16px;
  }

  .about__sub-container h1 {
    font-size: 1.5rem;
  }

  .about__headshot {
    float: left;
    margin: 15px;
    margin-left: 0;
    width: 50%;
    border: 5px skyblue solid;
    border-radius: 25px;
  }
}
