.project-card {
  background-color: skyblue;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.75);
  margin: 15px;
  width: 75%;
  color: black;
}

.project-card:hover,
.project-card:active {
  background-color: skyblue;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 10px #0ff;
  margin: 15px;
  width: 75%;
  color: black;
}

.accordion {
  position: relative;
}

.accordion p {
  position: absolute;
  top: 10px;
  left: 16px;
  color: magenta;
  font-weight: bold;
}

.project-card__image {
  bottom: 0;
  width: 100%;
}

.accordion__content {
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.accordion__text {
  margin: 0px 15px;
  padding: 5px 0px;
  font-size: 16px;
}

.accordion__icon {
  transition: transform 0.6s ease;
  position: absolute;
  bottom: 10px;
  right: 16px;
}

.rotate {
  transform: rotate(90deg);
}

@media (max-width: 768px) {
  .project-card {
    width: 100%;
  }

  .project-card:hover,
  .project-card:active {
    background-color: skyblue;
    overflow: hidden;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.75);
    width: 100%;
    color: black;
  }

  .accordion p {
    position: absolute;
    top: 0;
    left: 10px;
    color: magenta;
    font-weight: bold;
  }
}
