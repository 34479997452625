.toolbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: purple;
    height: 56px;
    z-index: 50;
}

.toolbar__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.toolbar__logo {
    margin-left: 1rem;
}

.toolbar__logo img {
    width: 45px;
    height: auto;
    padding-top: 5px;
}

.toolbar__logo a {
    color: white;
    text-decoration: none;
}

.spacer {
    flex: 1;
}

.toolbar_nav-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar_nav-items li {
    padding: 0 0.5rem
}

.toolbar_nav-items a {
    color: white;
    text-decoration: none;
}

.toolbar_nav-items a:hover,
.toolbar_nav-items a:active {
    color: orange;
}

@media (max-width: 768px) {
    .toolbar_nav-items {
        display: none;
    }
    .toolbar__toggle-button {
        margin-right: 15px;
    }
}

@media (min-width: 769px) {
    .toolbar__toggle-button {
        display: none;
    }

    .toolbar__logo {
        margin-left: 5px;
    }
}