.projects {
  min-height: 100%;
  background-image: url(./Blue_GrungeBG.jpg);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.projects__main-container {
  padding: 10% 10%;
  font-size: 20px;
}

.projects__sub-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-template-rows: auto;
  padding: 5% 5%;
  color: white;
  background: purple;
  border: 5px palevioletred solid;
  border-radius: 20px;
}

.projects__sub-container h1 {
  margin-top: 0;
  font-family: "Rock Salt", cursive;
  justify-content: center;
}

.projects__card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

@media (max-width: 768px) {
  .projects__main-container {
  padding: 10% 0%;
  font-size: 20px;
}
  .projects__sub-container {
    margin-top: 20%;
    margin-bottom: 20%;
  }
  .projects__sub-container h1 {
  margin-top: 0;
  font-family: "Rock Salt", cursive;
  text-align: center;
  font-size: 2rem;
}
}