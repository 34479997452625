.skills {
  min-height: 100%;
  background-image: url(./Blue_GrungeBG.jpg);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.skills__main-container {
  padding: 10% 10%;
  font-size: 20px;
}

.skills__sub-container {
  padding: 5% 5%;
  color: white;
  background: purple;
  border: 5px palevioletred solid;
  border-radius: 20px;
}

.skills__sub-container h1 {
  font-family: "Rock Salt", cursive;
  margin-top: 0;
}

.skills__sub-container button {
  margin-bottom: 35px;
  font-size: 1rem;
  background-color: white;
  color: black;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
}

.skills__sub-container button:focus {
  outline: none;
}

.skills__sub-container button:hover {
  font-size: 1rem;
  background-color: gold;
  color: black;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
}

.skills__sub-container button:active {
  font-size: 1rem;
  background-color: goldenrod;
  color: black;
  padding: 9px;
  border-radius: 10px;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
}

.skills__skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__skills-container i {
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 25px;
  font-size: 4rem;
  color: white;
}

.skills__skills-container ul {
  list-style: none;
  padding: 0;
}

.skills__skills-container li {
  padding-bottom: 10px;
}

.skills__icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.skills__icons h2 {
  color: yellow;
  text-decoration: underline;
}

.client-side,
.server-side,
.deployment,
.devops,
.project-management,
.social-media,
.art-and-design {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.icon-container p {
  color: white;
}

.svg {
  width: 64px;
  height: 63px;
  padding: 0 35px;
  margin: 0;
  fill: white;
}

@media (max-width: 768px) {
  .skills__main-container {
    padding: 25% 0%;
    font-size: 16px;
  }

  .skills__sub-container h1 {
    font-family: "Rock Salt", cursive;
    font-size: 1.2rem;
    margin-top: 0;
  }
  .skills__icons h2 {
    font-family: "Rock Salt", cursive;
    font-size: 1rem;
    text-decoration: underline;
  }
}
